import React from 'react';
import PropTypes from 'prop-types';
import Logo from 'images/Logo-bos.png';
import { Link as RouterLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { userSignIn } from 'appRedux/thunks/auth';
import { setAuthFields } from 'appRedux/actions';
import { parseQueryString, fm } from 'utils/string';
import {
  Box,
  Alert,
  ComposedTextField,
  IconButton,
  Grid,
  Container,
  Typography,
  InputAdornment,
  Link,
  Button,
} from 'components/library';
import { appRoutes } from 'utils/enum';
import LoadingButton from 'components/common/LoadingButton';
import { VisibilityIcon, VisibilityOffIcon } from 'components/library/icons';
import ErrorBoundary from 'components/common/ErrorBoundary';
import { Field, Form, Formik } from 'formik';
import Styles from './style.module.scss';
import LoginWithCode from './LoginWithCode';
import validationSchema from './validationSchema';

export class Login extends React.Component {
  constructor(props) {
    super(props);
    const { fortnoxAuthCode } = parseQueryString(props.location.search);
    props.setAuthFields({ errorMessage: '' });

    this.state = {

      authorizationCode: fortnoxAuthCode,
      showLoginWithCode: false,
    };
  }

  componentDidMount() {
    const { hash } = window.location;
    if (hash.includes('?')) {
      const queryString = hash.split('?')[1];
      const queryParams = new URLSearchParams(queryString);
      const newEmployee = queryParams.get('new_employee');
      if (newEmployee) {
        this.setState({
          showLoginWithCode: true,
        });
      }
    }
  }

  componentDidUpdate() {
    if (this.props.auth.token) {
      this.props.history.push('/');
    }
  }

  handleSubmit = (values, { setSubmitting }) => {
    this.props.userSignIn({ ...values, authorizationCode: this.state.authorizationCode });
    setSubmitting(false);
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleClickShowPassword = () => {
    const { hidePassword } = this.props.auth;
    this.props.setAuthFields({ hidePassword: !hidePassword });
  }

  onClickSendCode = () => {
    this.setState((prevState) => ({
      showLoginWithCode: !prevState.showLoginWithCode,
    }));
  };

  render() {
    const { showLoginWithCode } = this.state;
    const { application, auth } = this.props;
    const { hidePassword, errorMessage } = auth;
    const hasError = Boolean(errorMessage);

    return (
      <ErrorBoundary>
        <Container component="main" maxWidth="xs">
          <Grid container alignItems="center" alignContent="center" spacing={2}>
            <Grid item xs={12}>
              <Grid container justifyContent="flex-start">
                <img
                  height={42}
                  width="auto"
                  src={Logo}
                  alt="Bokning och Schema Online Logo"
                />
              </Grid>
            </Grid>
            <Grid item xs={12} mt={2} mb={4}>
              <Typography fontWeight={500} fontSize={28}>
                {fm('user_modal_login')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {showLoginWithCode ? (
                <LoginWithCode />
              ) : (
                <Formik
                  initialValues={{ email: '', password: '' }}
                  validationSchema={validationSchema}
                  onSubmit={this.handleSubmit}
                >
                  {({ errors, touched, isSubmitting }) => (
                    <Form>
                      <Grid container alignItems="center" justifyContent="center" spacing={2}>
                        <Grid item xs={12}>
                          <Field
                            as={ComposedTextField}
                            size="small"
                            type="email"
                            id="username"
                            name="email"
                            placeholder={fm('email', 'Email')}
                            autoFocus
                            fullWidth
                            label={fm('email', 'Email')}
                            error={touched.email && errors.email}
                            helperText={touched.email && errors.email}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            as={ComposedTextField}
                            size="small"
                            type={hidePassword ? 'password' : 'text'}
                            id="password"
                            placeholder={fm('password', 'Password')}
                            name="password"
                            autoComplete="password"
                            fullWidth
                            label={fm('password', 'Password')}
                            error={touched.password && errors.password}
                            helperText={touched.password && errors.password}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={this.handleClickShowPassword}
                                    edge="end"
                                  >
                                    {hidePassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        {hasError && (
                        <Grid item xs={12}>
                          <Alert severity="error">
                            {errorMessage}
                          </Alert>
                        </Grid>
                        )}

                        <Grid item xs={12} mt={1}>
                          <LoadingButton
                            fullWidth
                            type="submit"
                            id="login-form-submit"
                            loading={application.isLoading || isSubmitting}
                          >
                            {fm('login', 'Access Account')}
                          </LoadingButton>
                        </Grid>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              )}
            </Grid>

            <Grid item xs={12}>
              <Box textTransform="uppercase" textAlign="center">{fm('or')}</Box>
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                onClick={this.onClickSendCode}
                variant="outlined"
              >
                {showLoginWithCode
                  ? fm('login_with_password', 'Login with password')
                  : fm('login_without_password', 'Sign in with one-time code')}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                spacing={2}
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item mt={1}>
                  <Link
                    component={RouterLink}
                    to={appRoutes.forgotPassword}
                    underline="none"
                    fontWeight={500}
                  >
                    {fm('forgot_password')}
                  </Link>
                </Grid>
                <Grid item mt={1}>
                  <Box textAlign="right">
                    <Link
                      component={RouterLink}
                      to="/register"
                      underline="none"
                      fontWeight={500}
                    >
                      {fm('create_account')}
                    </Link>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  application: state.application,
});

Login.propTypes = {
  setAuthFields: PropTypes.func,
  userSignIn: PropTypes.func,
  auth: PropTypes.shape({
    errorMessage: PropTypes.string,
    hidePassword: PropTypes.bool,
    token: PropTypes.string,
  }),
  application: PropTypes.shape({
    isLoading: PropTypes.bool,
  }),
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default connect(mapStateToProps, { userSignIn, setAuthFields })(Login);

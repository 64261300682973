import { getFormattedPaginationFields } from 'appRedux/utility';
import { epochTimeToFloat, eachDayOfInterval } from 'utils/dateTime';
import { sortBy } from 'utils/lodash';

export const getFormattedEmployeeDetailedRecords = (response) => ({
  ...getFormattedPaginationFields(response),
  records: (response.records || []).map((record) => ({
    date: record.date,
    totalAbsenceDuration: epochTimeToFloat(record.totalAbsenceDuration),
    totalPresenceDuration: epochTimeToFloat(record.totalPresenceDuration),
    totalOrdersDuration: epochTimeToFloat(record.totalOrdersDuration),
    totalOrdersPunchDuration: epochTimeToFloat(record.totalOrdersPunchDuration),
    totalShiftPunchesDuration: epochTimeToFloat(record.totalShiftPunchesDuration),
  })),
});

export const getFormattedCustomerReports = (response) => ({
  ...getFormattedPaginationFields(response),
  records: (response.records || []).map((record) => ({
    ...record,
    totalOrdersDuration: epochTimeToFloat(record.totalOrdersDuration),
    activeOrdersDuration: epochTimeToFloat(record.activeOrdersDuration),
    completedOrdersDuration: epochTimeToFloat(record.completedOrdersDuration),
    cancelledOrdersDuration: epochTimeToFloat(record.cancelledOrdersDuration),
  })),
});

export const getFormattedSchedulesData = (response = {}, filters) => {
  const getTimeByInterval = (schedule, daysCount) => {
    const dayTimeMapping = [
      schedule.sundayTime,
      schedule.mondayTime,
      schedule.tuesdayTime,
      schedule.wednesdayTime,
      schedule.thursdayTime,
      schedule.fridayTime,
      schedule.saturdayTime,
    ];

    return dayTimeMapping.reduce(
      (sum, time, day) => sum + (daysCount[day] || 0) * time, 0,
    );
  };

  const daysInMonth = eachDayOfInterval(filters.startDate, filters.endDate);
  const daysCount = daysInMonth.reduce((dayCounts, date) => {
    const dayOfWeek = date.getDay();
    dayCounts[dayOfWeek] = (dayCounts[dayOfWeek] || 0) + 1;
    return dayCounts;
  }, {});

  return {
    ...getFormattedPaginationFields(response),
    records: (response.records || []).map((schedule) => ({
      id: schedule.id,
      timeByInterval: getTimeByInterval(schedule, daysCount),
    })),
  };
};

export const getFormattedServiceReports = (response) => ({
  ...getFormattedPaginationFields(response),
  records: (response.records || []).map((record) => ({
    ...record,
    totalOrdersDuration: epochTimeToFloat(record.totalWorkingHoursDuration),
    activeOrdersDuration: epochTimeToFloat(record.totalActiveHoursDuration),
    completedOrdersDuration: epochTimeToFloat(record.totalCompletedHoursDuration),
    cancelledOrdersDuration: epochTimeToFloat(record.totalCancelledHoursDuration),
  })),
});

export const getFormattedEmployeeRevenueReports = (response) => ({
  ...getFormattedPaginationFields(response),
  records: [...response.unassignedRecords, ...response.records] || [],
});

import { formatDate, formatStrings, getDifferenceBetweenLocalStrings, getDifferenceBetweenTime, getHourMinutesInUnixTime } from 'utils/dateTime';
import * as Yup from 'yup';
import { getTotalWorkingHoursForProjectOrderEmployees } from 'appRedux/utility';
import { fm } from 'utils/string';

export const getFormattedOrdersData = (data, user) => {
  const records = data.map((order) => {
    let employeeDetails = order.orderEmployees.find((emp) => emp.employeeId === user.id);
    const isPaidTimeAvailableForEmployee = Boolean(employeeDetails);
    if (user.isSupervisor && !employeeDetails) {
      employeeDetails = {
        isSupervisor: true,
        startTime: order.startTime ? order.startTime : null,
        endTime: order.endTime ? order.endTime : null,
      };
    }
    const employeeStartTime = employeeDetails.startTime && !employeeDetails.isSupervisor
      ? new Date(employeeDetails.startTime.split('+')[0])
      : employeeDetails.startTime;
    const employeeEndTime = employeeDetails.endTime && !employeeDetails.isSupervisor
      ? new Date(employeeDetails.endTime.split('+')[0])
      : employeeDetails.endTime;

    const formattedStartTime = employeeStartTime && employeeDetails.isSupervisor
      ? employeeStartTime
      : formatDate(employeeStartTime, formatStrings.defaulTime);
    const formattedEndTime = employeeEndTime && employeeDetails.isSupervisor
      ? employeeEndTime
      : formatDate(employeeEndTime, formatStrings.defaulTime);

    return {
      ...order,
      address: order.customerAddress,
      employeeStartTime: employeeDetails?.startTime ? formattedStartTime : '--:--',
      employeeEndTime: employeeDetails?.endTime ? formattedEndTime : '--:--',
      employeePaidTime: isPaidTimeAvailableForEmployee && formattedEndTime && formattedStartTime
        ? getHourMinutesInUnixTime(
          getTotalWorkingHoursForProjectOrderEmployees(employeeStartTime, employeeEndTime, employeeDetails.breakTime),
        )
        : 0,
      employeePunch: employeeDetails?.employeePunch,
      service: order.serviceName,
      sequenceNum: order.orderSequenceNum
        ? order.orderSequenceNum
        : `${order.projectSequenceNum}-${formatDate(order.date, formatStrings.customDate)}`,
      virtual: !order.orderId,
    };
  });

  return records;
};

export const getFormattedEmployeeOrdersList = (records, user) => ({
  records: getFormattedOrdersData(records, user),
});

export const getFormattedOrderChecklists = (response) => {
  const orderChecklists = Object.values(response).map((orderChecklist) => ({
    taskCategory: {
      id: orderChecklist[0].taskCategoryId,
      title: orderChecklist[0].taskCategoryTitle,
    },
    tasks: orderChecklist.map((task) => ({
      bookingTaskId: task.id,
      id: task.taskId,
      title: task.taskName,
      completed: task.completed,
    })),
  }));
  return orderChecklists;
};

export const getFormattedOrderData = (data) => {
  const formattedData = {
    ...data.order,
    service: data.order.service.title,
    address: data.order.customer.address,
    customerName: data.order.customer.name,
    customerMobile: data.order.customer.mobile,
    totalDocument: data.documents?.length || 0,
    customerKeys: data.customerKeys,
    customerAddresses: data.customerAddresses,
    customerNotes: data.customerNotes,
    orderChecklists: getFormattedOrderChecklists(data.orderChecklists || []),
    employeePresenceTimes: data.order.employeePresenceTimes.map((emp) => ({
      ...emp,
      startTime: emp.startTime ? formatDate(new Date(emp.startTime.split('+')[0]), formatStrings.defaulTime) : null,
      endTime: emp.endTime ? formatDate(new Date(emp.endTime.split('+')[0]), formatStrings.defaulTime) : null,
      employeeStartTime: emp.startTime ? emp.startTime.split('+')[0] : null,
      employeeEndTime: emp.endTime ? emp.endTime.split('+')[0] : null,
    })),
  };
  return formattedData;
};

export const getFormattedDataForVirtualOrder = (data, selectedDate, user) => {
  const { project } = data;
  let employee = project.projectEmployees.find((emp) => (emp.employeeId === user.id));
  if (user.isSupervisor && !employee) {
    employee = {
      startTime: project.startTime ? project.startTime : null,
      endTime: project.endTime ? project.endTime : null,
      breakTime: project.breakTime ? project.breakTime : null,
    };
  }
  const formattedData = {
    projectId: project.id,
    id: `${project.sequenceNum}-${formatDate(selectedDate, formatStrings.customDate)}`,
    address: project.customer.address,
    breakTime: employee.breakTime,
    customer: project.customer,
    customerKeys: data.customerKeys,
    customerAddresses: data.customerAddresses,
    customerNotes: data.customerNotes,
    service: project.service.title,
    customerName: project.customer.name,
    customerMobile: project.customer.mobile,
    date: selectedDate,
    employeeConfirmationRequired: project.employeeConfirmationRequired,
    startTime: project.startTime,
    endTime: project.endTime,
    orderAddresses: project.projectAddresses,
    employeePresenceTimes: project.projectEmployees.map((emp) => ({
      ...emp,
      startTime: emp.startTime ? formatDate(new Date(emp.startTime.split('+')[0]), formatStrings.defaulTime) : null,
      endTime: emp.endTime ? formatDate(new Date(emp.endTime.split('+')[0]), formatStrings.defaulTime) : null,
      employeeStartTime: emp.startTime ? emp.startTime.split('+')[0] : null,
      employeeEndTime: emp.endTime ? emp.endTime.split('+')[0] : null,
    })),
    orderItems: project.projectItems,
    orderNote: project.projectNote,
    orderTags: project.projectTags,
    status: project.status,
    timeType: project.timeType,
    totalHours: project.totalHours,
    flexTimeType: project.flexTimeType,
    projectRecurringRule: project.projectRecurringRule,
    orderChecklists: getFormattedOrderChecklists(data.projectChecklists || []),
    virtual: true,
  };
  return formattedData;
};

export const getFormattedBodyForEditOrderTime = (data) => {
  const body = {
    employee_presence_times: {
      start_time: data.bookingStartTime,
      end_time: data.bookingEndTime,
      break_time: data.breakTime === 0 ? '00:00' : data.breakTime,
    },
    comment: data.comment || '',
  };
  return body;
};

const validateOrderTime = Yup.object().shape({
  bookingStartTime: Yup.string()
    .when('order', {
      is: false,
      then: Yup.string()
        .required(fm('employee_booking_time_start_time_required'))
        .test(
          'bookingStartTime',
          fm('employee_booking_start_time_greater'),
          function (bookingStartTime) { // eslint-disable-line
            const { bookingEndTime } = this.parent;
            return getHourMinutesInUnixTime(bookingStartTime) < getHourMinutesInUnixTime(bookingEndTime);
          },
        ),
      otherwise: Yup.string().nullable(),
    }),
  bookingEndTime: Yup.string()
    .when('order', {
      is: false,
      then: Yup.string()
        .required(fm('employee_booking_time_end_time_required')),
      otherwise: Yup.string().nullable(),
    }),
  breakTime: Yup.string().required(fm('employee_booking_time_break_time_required')).test(
    'breakTime',
    fm('employee_booking_break_time_smaller'),
    function (breakTime) { // eslint-disable-line
      const { bookingStartTime, bookingEndTime } = this.parent;
      const time = getDifferenceBetweenTime(
        getDifferenceBetweenLocalStrings(bookingEndTime, bookingStartTime),
        breakTime,
      );
      return breakTime === '00:00' || !time.includes('-');
    },
  ),
});

export const validateAndGetFormattedEmployeeOrderTimeBody = async (obj) => {
  await validateOrderTime.validate(obj);
  return getFormattedBodyForEditOrderTime(obj);
};

export const getFormattedBodyForPunchInOut = (data) => {
  const body = {
    employee_punches: {
      company_id: data.companyId,
      employee_id: data.employeeId,
      employee_presence_time_id: data.employeePresenceTimeId,
      punch_in_time: data.punchInTime,
      punch_out_time: data.punchOutTime,
      punch_in_latitude: data.punchInLatitude,
      punch_in_longitude: data.punchInLongitude,
      punch_in_device: data.punchInDevice,
      punch_out_latitude: data.punchOutLatitude,
      punch_out_longitude: data.punchOutLongitude,
      punch_out_device: data.punchOutDevice,
    },
    order_id: data.orderId,
  };
  return body;
};

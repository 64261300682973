import {
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  SET_AUTH_FIELDS,
  RESET_AUTH_FIELDS,
} from '../actions';

const initialState = {
  authUser: JSON.parse(localStorage.getItem('user')),
  initURL: '',
  contractDetails: {},
  role: localStorage.getItem('role'),
  token: localStorage.getItem('token'),
  mainCompanyToken: localStorage.getItem('main_company_token'),
  errors: {},
  signupFormErrors: {},
  hidePassword: true,
  fsip: false,
  resetPasswordError: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case INIT_URL: {
      return {
        ...state,
        initURL: action.payload,
      };
    }

    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        authUser: null,
        initURL: '',
        role: null,
        token: null,
        mainCompanyToken: null,
      };
    }

    case USER_DATA: {
      return {
        ...state,
        authUser: action.payload,
      };
    }

    case SET_AUTH_FIELDS: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case RESET_AUTH_FIELDS: {
      return initialState;
    }

    default:
      return state;
  }
};

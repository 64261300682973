import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { submitButtonTypes } from 'components/Order/utility';
import { requestExportOrdersPdf, requestPdfOrder, requestPdfVirtualOrder } from './pdfThunk';
import {
  requestOrders,
  addPredefinedChecklistToOrder,
  requestCreateOrder,
  requestUpdateOrder,
  requestOrder,
  requestCompleteOrder,
  requestCancelOrder,
  requestActivateOrder,
  requestOrderToCopy,
  requestDeleteOrder,
  requestDeleteOrderFromCalendar,
  requestDeleteVirtualOrderFromCalendar,
  requestOrderActivity,
  requestBulkUpdateOrdersStatus,
  requestDeleteOrderDocument,
  requestBulkDeleteOrders,
  requestFreezeOrder,
  requestFreezeVirtualOrder,
  requestUpdateOrderInvoiceId,
  requestConversations,
  requestAllConversations,
  requestCreateConversationForVirtualOrder,
  requestSendVirtualOrderEmail,
  requestSendBulkEmail,
  requestCompleteVirtualOrder,
  requestDeleteVirtualOrder,
  requestCancelVirtualOrder,
  requestSendVirtualOrderSms,
  requestApproveVirtualOrderEmployeeTime,
  requestSendOrderSms,
  requestOrdersStats,
  requestOrderCustomerRequests,
  requestApproveCustomerRequest,
  requestDenyCustomerRequest,
  requestOrdersCustomerRequests,
  requestVirtualOrder,
  requestSendOrderEmail,
  requestSendVirtualOrderTestEmail,
  requestSendOrderTestEmail,
  requestMarkConversationAsRead,
  requestOrderSmsEmailHistory,
  requestApproveEmployeeTime,
  requestDeleteEmployeePresenceTimeApproval,
  requestCompleteVirtualOrderFromCalendar,
  requestCompleteOrderFromCalendar,
  requestCancelOrderFromCalendar,
  requestCancelVirtualOrderFromCalendar,
  requestActivateOrderFromCalendar,
  requestOrderToCopyFromCalendar,
  requestAddOrderEmployeesFromCalendar,
  requestUpdateOrderEmployeesFromCalendar,
  requestApproveEmployeeTimeFromCalendar,
  requestApproveVirtualOrderEmployeeTimeFromCalendar,
  requestDeleteEmployeePresenceTimeApprovalFromCalendar,
  requestRemoveVirtualOrderEmployeeFromCalendar,
} from './thunk';
import { RESET_ORDER_STATE } from './actions';

const orders = createSlice({
  name: 'orders',
  initialState: {
    records: [],
    order: {
      orderEmployees: [],
      repeatDays: [],
      orderChecklists: [],
      project: {},
      skipAttribute: {},
      published: false,
      employeeConfirmationRequired: false,
    },
    loadingEvents: {},
    ordersPdf: { open: false, order: null, orders: [], virtualOrders: [] },
    fsip: false,
    selectedOrderSequenceNums: [],
    refreshAllOrders: {},
    showOrderDialog: false,
    orderDocuments: {
      records: [],
    },
    conversations: {
      records: [],
    },
    allConversations: {
      records: [],
    },
    activity: {
      records: [],
    },
    orderSmsEmailHistory: {
      sms: [],
      email: [],
    },
    customerRequests: {
      records: [],
      refreshAllCustomerRequests: {},
    },
    allCustomerRequests: {
      records: [],
      refreshAllCustomerRequests: {},
    },
    orderStats: {},
  },
  reducers: {
    setOrdersFields (state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
    setOrdersPdfFields (state, action) {
      return {
        ...state,
        ordersPdf: {
          ...state.ordersPdf,
          ...action.payload,
        },
      };
    },
    setLoadingEvents (state, action) {
      return {
        ...state,
        loadingEvents: {
          ...state.loadingEvents,
          [action.payload.sequenceNum]: action.payload.isLoading,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_ORDER_STATE, (state) => {
      state.showOrderDialog = false;
      state.showOrderUpdateOptionsDialog = false;
      state.openedOrderId = null;
      state.order = {};
      state.refreshAllOrders = {};
      state.fsip = false;
    });
    builder.addCase(requestOrders.fulfilled, (state, action) => ({
      ...state,
      records: action.payload.records,
      selectedOrderSequenceNums: [],
    }));
    builder.addCase(requestOrder.fulfilled, (state, action) => ({
      ...state,
      updatedOrderId: null,
      order: action.payload.order,
      orderDocuments: action.payload.orderDocuments,
      fsip: false,
      companyId: undefined,
    }));
    builder.addCase(requestOrdersStats.fulfilled, (state, action) => ({
      ...state,
      orderStats: action.payload.orderStats,
    }));
    builder.addCase(requestOrderSmsEmailHistory.fulfilled, (state, action) => ({
      ...state,
      orderSmsEmailHistory: {
        ...state.orderSmsEmailHistory,
        ...action.payload,
      },
    }));
    builder.addCase(requestCompleteOrder.fulfilled, (state, action) => ({
      ...state,
      refreshAllOrders: {},
      selectedOrderSequenceNums: [],
      openedOrderId: action.payload.orderId,
      loadingEvents: { ...state.loadingEvents, [action.payload.sequenceNum]: false },
    }));
    builder.addCase(requestCancelOrder.fulfilled, (state, action) => ({
      ...state,
      refreshAllOrders: {},
      selectedOrderSequenceNums: [],
      openedOrderId: action.payload.orderId,
      loadingEvents: { ...state.loadingEvents, [action.payload.sequenceNum]: false },
    }));
    builder.addCase(requestActivateOrder.fulfilled, (state, action) => ({
      ...state,
      refreshAllOrders: {},
      selectedOrderSequenceNums: [],
      openedOrderId: action.payload.orderId,
      loadingEvents: { ...state.loadingEvents, [action.payload.sequenceNum]: false },
    }));
    builder.addCase(addPredefinedChecklistToOrder.fulfilled, (state, action) => ({
      ...state,
      ...action.payload,
    }));
    builder.addCase(requestOrderCustomerRequests.fulfilled, (state, action) => ({
      ...state,
      customerRequests: {
        records: action.payload.records,
      },
    }));
    builder.addCase(requestVirtualOrder.fulfilled, (state, action) => ({
      ...state,
      order: action.payload.order,
      orderDocuments: action.payload.orderDocuments,
      fsip: false,
    }));
    builder.addCase(requestOrdersCustomerRequests.fulfilled, (state, action) => ({
      ...state,
      allCustomerRequests: {
        records: action.payload.records,
      },
    }));
    builder.addCase(requestApproveCustomerRequest.fulfilled, (state) => ({
      ...state,
      refreshAllOrders: {},
      customerRequests: {
        refreshAllCustomerRequests: {},
      },
    }));
    builder.addCase(requestFreezeOrder.fulfilled, (state, action) => ({
      ...state,
      refreshAllOrders: {},
      selectedOrderSequenceNums: [],
      openedOrderId: action.payload.orderId,
    }));
    builder.addCase(requestUpdateOrderInvoiceId.fulfilled, (state) => ({
      ...state,
      refreshAllOrders: {},
      selectedOrderSequenceNums: [],
      showInvoiceIdDialog: false,
      fsip: false,
    }));
    builder.addCase(requestOrderToCopy.fulfilled, (state, action) => ({
      ...state,
      ...action.payload,
      openedOrderId: null,
      selectedOrderSequenceNums: [],
    }));
    builder.addCase(requestOrderToCopyFromCalendar.fulfilled, (state, action) => ({
      ...state,
      ...action.payload,
      openedOrderId: null,
      selectedOrderSequenceNums: [],
      showOrderDialog: true,
    }));
    builder.addCase(requestOrderActivity.fulfilled, (state, action) => ({
      ...state,
      activity: {
        records: action.payload.records,
      },
    }));
    builder.addCase(requestDenyCustomerRequest.fulfilled, (state) => ({
      ...state,
      refreshAllOrders: {},
      customerRequests: {
        refreshAllCustomerRequests: {},
      },
    }));
    builder.addCase(requestSendBulkEmail.fulfilled, (state) => ({
      ...state,
      refreshAllOrders: {},
      selectedOrderSequenceNums: [],
      fsip: false,
    }));
    builder.addCase(requestConversations.fulfilled, (state, action) => ({
      ...state,
      conversations: {
        records: action.payload.records,
      },
    }));
    builder.addCase(requestAllConversations.fulfilled, (state, action) => ({
      ...state,
      allConversations: {
        records: action.payload.records,
      },
    }));
    builder.addCase(requestCreateConversationForVirtualOrder.fulfilled, (state, action) => ({
      ...state,
      openedOrderId: action.payload.orderId,
      order: action.payload.order,
      orderDocuments: action.payload.orderDocuments,
      refreshAllOrders: {},
    }));
    builder.addCase(requestMarkConversationAsRead.fulfilled, (state, action) => ({
      ...state,
      allConversations: {
        records: action.payload.records,
      },
    }));
    builder.addCase(requestSendOrderSms.fulfilled, (state, action) => ({
      ...state,
      openedOrderId: action.payload.orderId,
      refreshAllOrders: {},
      fsip: false,
    }));
    builder.addCase(requestBulkUpdateOrdersStatus.fulfilled, (state) => ({
      ...state,
      selectedOrderSequenceNums: [],
      refreshAllOrders: {},
    }));
    builder.addCase(requestBulkDeleteOrders.fulfilled, (state) => ({
      ...state,
      selectedOrderSequenceNums: [],
      refreshAllOrders: {},
    }));
    builder.addCase(requestDeleteOrderDocument.fulfilled, (state, action) => ({
      ...state,
      orderDocuments: action.payload.orderDocuments,
    }));
    builder.addCase(requestSendVirtualOrderSms.fulfilled, (state, action) => ({
      ...state,
      openedOrderId: action.payload.orderId,
      refreshAllOrders: {},
      fsip: false,
    }));
    builder.addCase(requestExportOrdersPdf.fulfilled, (state) => ({
      ...state,
      ordersPdf: { open: false, order: null, orders: [], virtualOrders: [] },
      selectedOrderSequenceNums: [],
      refreshAllOrders: {},
    }));
    builder.addCase(requestRemoveVirtualOrderEmployeeFromCalendar.fulfilled, (state) => ({
      ...state,
      refreshAllOrders: {},
    }));
    builder.addMatcher(
      isAnyOf(requestPdfOrder.fulfilled, requestPdfVirtualOrder.fulfilled),
      (state, action) => ({
        ...state,
        ordersPdf: { ...state.ordersPdf, order: action.payload.order },
      }),
    );
    builder.addMatcher(
      isAnyOf(requestApproveEmployeeTime.fulfilled, requestDeleteEmployeePresenceTimeApproval.fulfilled),
      (state, action) => ({
        ...state,
        selectedEmployee: action.payload.selectedEmployee,
      }),
    );
    builder.addMatcher(
      isAnyOf(requestSendOrderEmail.fulfilled, requestSendVirtualOrderTestEmail.fulfilled,
        requestSendOrderTestEmail.fulfilled, requestSendOrderEmail.rejected),
      (state) => ({
        ...state,
        fsip: false,
      }),
    );
    builder.addMatcher(
      isAnyOf(requestSendVirtualOrderEmail.fulfilled),
      (state, action) => ({
        ...state,
        openedOrderId: action.payload.orderId,
        order: action.payload.order,
        orderDocuments: action.payload.orderDocuments,
        fsip: false,
        refreshAllOrders: {},
      }),
    );
    builder.addMatcher(
      isAnyOf(
        requestCancelVirtualOrder.fulfilled,
        requestCompleteVirtualOrder.fulfilled,
        requestFreezeVirtualOrder.fulfilled,
      ),
      (state, action) => ({
        ...state,
        refreshAllOrders: {},
        selectedOrderSequenceNums: [],
        openedOrderId: action.payload.orderId,
        loadingEvents: { ...state.loadingEvents, [action.payload.sequenceNum]: false },
      }),
    );
    builder.addMatcher(
      isAnyOf(
        requestCompleteVirtualOrderFromCalendar.fulfilled,
        requestCompleteVirtualOrderFromCalendar.rejected,
        requestCompleteOrderFromCalendar.fulfilled,
        requestCompleteOrderFromCalendar.rejected,
        requestCancelOrderFromCalendar.fulfilled,
        requestCancelOrderFromCalendar.rejected,
        requestCancelVirtualOrderFromCalendar.fulfilled,
        requestCancelVirtualOrderFromCalendar.rejected,
        requestActivateOrderFromCalendar.fulfilled,
        requestActivateOrderFromCalendar.rejected,
        requestDeleteOrderFromCalendar.fulfilled,
        requestDeleteOrderFromCalendar.rejected,
        requestDeleteVirtualOrderFromCalendar.fulfilled,
        requestDeleteVirtualOrderFromCalendar.rejected,
      ),
      (state, action) => ({
        ...state,
        refreshAllOrders: {},
        selectedOrderSequenceNums: [],
        loadingEvents: { ...state.loadingEvents, [action.payload.sequenceNum]: false },
      }),
    );
    builder.addMatcher(
      isAnyOf(
        requestAddOrderEmployeesFromCalendar.fulfilled,
        requestAddOrderEmployeesFromCalendar.rejected,
        requestUpdateOrderEmployeesFromCalendar.fulfilled,
        requestUpdateOrderEmployeesFromCalendar.rejected,
        requestRemoveVirtualOrderEmployeeFromCalendar.fulfilled,
        requestRemoveVirtualOrderEmployeeFromCalendar.rejected,
      ),
      (state, action) => ({
        ...state,
        fsip: false,
        loadingEvents: { ...state.loadingEvents, [action.payload.sequenceNum]: false },
        refreshAllOrders: {},
      }),
    );
    builder.addMatcher(
      isAnyOf(requestApproveVirtualOrderEmployeeTime.fulfilled),
      (state, action) => ({
        ...state,
        order: action.payload.order,
        refreshAllOrders: {},
        updatedOrderId: null,
        openedOrderId: action.payload.orderId,
        orderDocuments: action.payload.orderDocuments,
        selectedEmployee: action.payload.selectedEmployee,
        fsip: false,
      }),
    );
    builder.addMatcher(
      isAnyOf(
        requestApproveVirtualOrderEmployeeTimeFromCalendar.fulfilled,
        requestApproveEmployeeTimeFromCalendar.fulfilled,
        requestDeleteEmployeePresenceTimeApprovalFromCalendar.fulfilled,
      ),
      (state, action) => ({
        ...state,
        loadingEvents: { ...state.loadingEvents, [action.payload.sequenceNum]: false },
        refreshAllOrders: {},
        updatedOrderId: action.payload.orderId,
      }),
    );
    builder.addMatcher(
      isAnyOf(requestDeleteOrder.fulfilled, requestDeleteVirtualOrder.fulfilled),
      (state) => ({
        ...state,
        showOrderDialog: false,
        openedOrderId: null,
        refreshAllOrders: {},
        selectedOrderSequenceNums: [],
        order: {},
      }),
    );
    builder.addMatcher(
      isAnyOf(requestCreateOrder.fulfilled, requestUpdateOrder.fulfilled),
      (state, action) => ({
        ...state,
        ...(action.payload.submitButtonType === submitButtonTypes.save && {
          openedOrderId: action.payload.orderId,
        }),
        ...(action.payload.submitButtonType === submitButtonTypes.saveAndClose && {
          showOrderDialog: false,
          openedOrderId: null,
          order: {},
        }),
        refreshAllOrders: {},
        fsip: false,
        selectedOrderSequenceNums: [],
        showOrderUpdateOptionsDialog: false,
      }),
    );
    builder.addMatcher(
      isAnyOf(requestCreateOrder.rejected, requestUpdateOrder.rejected,
        requestSendVirtualOrderEmail.rejected, requestOrder.rejected,
        requestOrderCustomerRequests.rejected),
      (state) => ({
        ...state,
        fsip: false,
        refreshAllOrders: {},
      }),
    );
  },
});

const { reducer, actions } = orders;

export const { setOrdersFields, setOrdersPdfFields, setLoadingEvents } = actions;

export default reducer;

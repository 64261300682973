import React from 'react';
import PropTypes from 'prop-types';
import { HashRouter } from 'react-router-dom';
import { debounce } from 'utils/lodash';
import seLocale from 'date-fns/locale/sv';
import enLocale from 'date-fns/locale/en-GB';
import { IntlProvider, addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import se from 'react-intl/locale-data/se';
import { toggleDevice, setApplicationFields } from 'appRedux/actions';
import CssBaseline from '@mui/material/CssBaseline';
import { connect } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CacheBuster from './CacheBuster';
import messagesSe from './translations/se';
import messagesEn from './translations/en';
import Routes from './Routes';

addLocaleData([...en, ...se]);
// some comment
class App extends React.Component {
  messages = {
    se: messagesSe,
    en: messagesEn,
  };

  datePickerLocale = {
    se: seLocale,
    en: enLocale,
  };

  constructor (props) {
    super(props);
    this.toggleDeviceSize();
  }

  componentDidMount () {
    window.addEventListener('resize', debounce(150)(this.toggleDeviceSize));
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.toggleDeviceSize);
  }

  toggleDeviceSize = () => {
    let size = 'xl';
    if (window.innerWidth < 576) {
      size = 'xs';
    } else if (window.innerWidth < 768) {
      size = 'sm';
    } else if (window.innerWidth < 992) {
      size = 'md';
    } else if (window.innerWidth < 1200) {
      size = 'lg';
    }

    this.props.toggleDevice({ size });
  }

  setApplicationVersion = (appVersion) => {
    this.props.setApplicationFields({ appVersion });
  }

  render () {
    const { language } = this.props;

    return (
      <CacheBuster setAppVersion={this.setApplicationVersion}>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            // You can decide how and when you want to force reload
            refreshCacheAndReload();
          }

          return (
            <IntlProvider locale={language} messages={this.messages[language]}>
              <HashRouter basename="/">
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={this.datePickerLocale[language]}>
                  <CssBaseline />
                  <Routes />
                </LocalizationProvider>
              </HashRouter>
            </IntlProvider>
          );
        }}
      </CacheBuster>
    );
  }
}

const mapState = (state) => ({
  language: state.profile.language,
  auth: state.auth,
});

App.propTypes = {
  language: PropTypes.string.isRequired,
  toggleDevice: PropTypes.func.isRequired,
  setApplicationFields: PropTypes.func.isRequired,
};

export default connect(mapState, { toggleDevice, setApplicationFields })(App);

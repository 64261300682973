import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { fm } from 'utils/string';
import { useFormik } from 'formik';
import {
  CircularProgress,
  Grid,
  ComposedTextField,
  FormControlLabel,
  Box,
  ErrorMessage,
  Button,
  Checkbox,
  Link,
  Select,
  MenuItem,
  InputAdornment,
  IconButton,
} from 'components/library';
import urls from 'utils/urls';
import { organisationTypesList } from 'utils/constant';
import { Link as ButtonLink } from 'react-router-dom';
import { organisationTypes } from 'utils/enum';
import { VisibilityIcon, VisibilityOffIcon } from 'components/library/icons';
import useStyles from './styles';
import validationSchema from './validationSchema';

export default function SignupForm (props) {
  const classes = useStyles();
  const application = useSelector((state) => state.application);
  const auth = useSelector((state) => state.auth);
  const { signupFormErrors } = auth;
  const [hidePassword, setHidePassword] = useState(true);

  const { values, handleChange, handleSubmit, handleBlur, errors, touched } = useFormik({
    initialValues: {
      email: props.defaultEmail,
      password: '',
      passwordConfirmation: '',
      name: '',
      companyName: '',
      acceptTerms: false,
      orgType: organisationTypes.limitedLiability,
      identificationNum: '',
    },
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: props.onSubmit,
  });

  const handleClickShowPassword = () => {
    setHidePassword((prevHidePassword) => !prevHidePassword);
  };

  return (
    <Grid item xs={12} mt={1}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ComposedTextField
              autoFocus
              size="small"
              fullWidth
              name="name"
              id="name"
              error={touched.name && Boolean(errors.name)}
              helperText={touched.name && errors.name}
              label={fm('name')}
              placeholder={fm('enter_name')}
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}

            />
          </Grid>
          <Grid item xs={12}>
            <ComposedTextField
              name="companyName"
              id="company_name"
              error={touched.companyName && Boolean(errors.companyName)}
              helperText={touched.companyName && errors.companyName}
              label={fm('company_name')}
              value={values.companyName}
              onChange={handleChange}
              onBlur={handleBlur}
              size="small"
              fullWidth
              placeholder={fm('company_name')}
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              name="orgType"
              value={values.orgType}
              onChange={handleChange}
              inputLabelId="organisation_type"
              label={fm('Organisation type', 'Organisation type')}
              fullWidth
              error={touched.orgType && Boolean(errors.orgType)}
              helperText={touched.orgType && errors.orgType}
            >
              {organisationTypesList.map(
                (type) => <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>,
              )}
            </Select>
          </Grid>
          <Grid item xs={12}>
            <ComposedTextField
              type="string"
              size="small"
              id="identificationNum"
              name="identificationNum"
              placeholder={fm('identification_num')}
              fullWidth
              value={values.identificationNum}
              error={touched.identificationNum
                 && (Boolean(signupFormErrors.identificationNum || errors.identificationNum))}
              helperText={touched.identificationNum && (signupFormErrors.identificationNum || errors.identificationNum)}
              onChange={handleChange}
              onBlur={handleBlur}
              label={fm('identification_num')}
            />
          </Grid>
          <Grid item xs={12}>
            <ComposedTextField
              type="email"
              size="small"
              id="Email"
              name="email"
              placeholder={fm('enter_email')}
              fullWidth
              value={values.email}
              error={touched.email && (Boolean(signupFormErrors.email || errors.email))}
              helperText={touched.email && (signupFormErrors.email || errors.email)}
              onChange={handleChange}
              onBlur={handleBlur}
              label={fm('email')}
            />
          </Grid>
          <Grid item xs={12}>
            <ComposedTextField
              type={hidePassword ? 'password' : 'text'}
              size="small"
              name="password"
              id="password"
              autoComplete="password"
              error={touched.password && Boolean(errors.password)}
              helperText={touched.password && errors.password}
              label={fm('password')}
              value={values.password}
              onChange={handleChange}
              placeholder={fm('choose_password')}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {hidePassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <ComposedTextField
              name="passwordConfirmation"
              id="password_confirm"
              autoComplete="password"
              error={touched.passwordConfirmation && Boolean(errors.passwordConfirmation)}
              helperText={touched.passwordConfirmation && errors.passwordConfirmation}
              label={fm('confirm_password')}
              value={values.passwordConfirmation}
              onChange={handleChange}
              onBlur={handleBlur}
              type={hidePassword ? 'password' : 'text'}
              size="small"
              placeholder={fm('confirm_password')}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {hidePassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ul>
            <li>{fm('use_few_words')}</li>
            <li>{fm('symbol_msg')}</li>
          </ul>
        </Grid>
        <Box display="flex" alignItems="center" my={2} ml={1}>
          <FormControlLabel
            className="mr-2"
            onChange={handleChange}
            onBlur={handleBlur}
            name="acceptTerms"
            control={<Checkbox checked={values.acceptTerms} value="Policy" color="primary" />}
            label={fm('accept_terms_conditions')}
          />
          <Link
            target="_blank"
            rel="noopener"
            href={urls.getPrivacyPolicyPdfLink()}
          >
            {fm('privacy_policy')}
          </Link>
        </Box>
        <ErrorMessage text={errors.acceptTerms} />
        <Grid item xs={12}>
          <Grid justifyContent="center" alignItems="center">

            <Grid item>
              <Button
                type="submit"
                fullWidth
              >
                {application.isLoading
                  ? <CircularProgress size={25} color="inherit" />
                  : fm('register')}
              </Button>
            </Grid>
          </Grid>
          <Grid justifyContent="center" alignItems="center">
            <Grid item>
              <Box display="flex" fontWeight={500} justifyContent="center" alignItems="center" my={2} ml={1}>
                {fm('already_account')}
                <ButtonLink to="/login" variant="body2" className={classes.link}>
                  {fm('login')}
                </ButtonLink>
              </Box>

            </Grid>

          </Grid>
        </Grid>
      </form>
    </Grid>
  );
}

SignupForm.propTypes = {
  onSubmit: PropTypes.func,
  defaultEmail: PropTypes.string,
};

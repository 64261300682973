import { useFormik } from 'formik';
import * as Yup from 'yup';
import { fm } from 'utils/string';
import { requestSignInOtp, userSignIn } from 'appRedux/thunks/auth';

export const useEmailFormik = (dispatch, setShowOtpInput) => useFormik({
  initialValues: {
    email: '',
  },
  validationSchema: Yup.object({
    email: Yup.string()
      .email(fm('invalid_email', 'Invalid email address'))
      .required(fm('email_required', 'Email is required')),
  }),
  onSubmit: (values) => {
    dispatch(requestSignInOtp(values.email));
    setShowOtpInput(true);
  },
});

export const useOtpFormik = (dispatch, emailFormik, history) => useFormik({
  initialValues: {
    otp: '',
  },
  validationSchema: Yup.object({
    otp: Yup.string().required(fm('otp_required'))
      .length(6, fm('opt_length')),
  }),
  onSubmit: (values) => {
    dispatch(userSignIn({ email: emailFormik.values.email, otp: values.otp }, history));
  },
});

import { formatDate, formatStrings, add } from 'utils/dateTime';
import {
  SET_USERS_FIELDS,
  SET_EMPLOYEE_AVAILABILITY_FIELDS,
  SET_PAGINATED_EMPLOYEE_AVAILABILITY_FIELDS,
  SET_USERS_LOGS_FIELDS,
  SET_USERS_DEPARTMENT_FIELDS,
  SET_USERS_USER_GROUP_FIELDS,
  SET_USERS_AREA_FIELDS,
  SET_USERS_BOOKING_TIME_FIELDS,
  SET_USERS_INTERNAL_TIME_FIELDS,
  SET_USERS_BOOKING_TIME_FILTERS,
  SET_USERS_REQUEST_FIELDS,
  SET_USERS_REQUEST_FILTERS,
  SET_USERS_INTERNAL_TIME_FILTERS,
  SET_EMPLOYEE_DOCUMENTS_FIELDS,
} from '../actions';

const initialTimeFilters = {
  startDate: formatDate(add(new Date(), { months: -1 }), formatStrings.filtersDate),
  endDate: formatDate(new Date(), formatStrings.filtersDate),
  page: 1,
  perPage: 5,
  totalRecords: 0,
};

const initialRequestFilters = {
  startDate: formatDate(add(new Date(), { months: -1 }), formatStrings.filtersDate),
  endDate: formatDate(new Date(), formatStrings.filtersDate),
  page: 1,
  perPage: 10,
  totalRecords: 0,
};

const initialState = {
  records: [],
  selectedUserIds: [],
  errors: {},
  paginatedEmployeeeAvailability: {
    records: [],
  },
  employeeAvailability: {},
  usersLogs: {
    records: [],
  },
  userGroup: {
    records: [],
  },
  department: {
    records: [],
  },
  area: {
    records: [],
  },
  userHistory: [],
  userDialogErrors: {},
  bookingTime: {
    records: [],
    filters: initialTimeFilters,
  },
  internalTime: {
    records: [],
    filters: initialTimeFilters,
  },
  requests: {
    records: [],
    filters: initialRequestFilters,
  },
  userDocuments: {
    showDocumentsDialog: false,
    records: [],
    imageViewer: {},
  },
};

function users (state = initialState, action) {
  switch (action.type) {
    case SET_USERS_FIELDS:
      return {
        ...state,
        ...action.payload,
      };
    case SET_EMPLOYEE_AVAILABILITY_FIELDS: {
      return {
        ...state,
        employeeAvailability: {
          ...state.employeeAvailability,
          ...action.payload,
        },
      };
    }
    case SET_PAGINATED_EMPLOYEE_AVAILABILITY_FIELDS: {
      return {
        ...state,
        paginatedEmployeeeAvailability: {
          ...state.paginatedEmployeeeAvailability,
          ...action.payload,
        },
      };
    }
    case SET_USERS_LOGS_FIELDS:
      return {
        ...state,
        usersLogs: {
          ...state.usersLogs,
          ...action.payload,
        },
      };
    case SET_USERS_DEPARTMENT_FIELDS:
      return {
        ...state,
        department: {
          ...state.department,
          ...action.payload,
        },
      };
    case SET_USERS_USER_GROUP_FIELDS:
      return {
        ...state,
        userGroup: {
          ...state.userGroup,
          ...action.payload,
        },
      };
    case SET_USERS_AREA_FIELDS:
      return {
        ...state,
        area: {
          ...state.area,
          ...action.payload,
        },
      };
    case SET_USERS_BOOKING_TIME_FIELDS:
      return {
        ...state,
        bookingTime: {
          ...state.bookingTime,
          ...action.payload,
        },
      };
    case SET_USERS_INTERNAL_TIME_FIELDS:
      return {
        ...state,
        internalTime: {
          ...state.internalTime,
          ...action.payload,
        },
      };
    case SET_USERS_BOOKING_TIME_FILTERS:
      return {
        ...state,
        bookingTime: {
          ...state.bookingTime,
          filters: {
            ...state.bookingTime.filters,
            ...action.payload,
          },
        },
      };
    case SET_USERS_INTERNAL_TIME_FILTERS:
      return {
        ...state,
        internalTime: {
          ...state.internalTime,
          filters: {
            ...state.internalTime.filters,
            ...action.payload,
          },
        },
      };
    case SET_USERS_REQUEST_FIELDS:
      return {
        ...state,
        requests: {
          ...state.requests,
          ...action.payload,
        },
      };
    case SET_USERS_REQUEST_FILTERS:
      return {
        ...state,
        requests: {
          ...state.requests,
          filters: {
            ...state.requests.filters,
            ...action.payload,
          },
        },
      };
    case SET_EMPLOYEE_DOCUMENTS_FIELDS:
      return {
        ...state,
        userDocuments: {
          ...state.userDocuments,
          ...action.payload,
        },
      };

    default:
      return state;
  }
}

export default users;
